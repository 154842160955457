<template>
    <div class="px-3">
        <div class="point_wrap bg-main p-4">
            <div class="d-flex justify-content-between">
            <div class="d-flex flex-column" @click="navigateToGiftCard();">
                <small class="text-white">보유 상품권</small>
                <div class="d-flex align-items-center text-white">
                <b class="fs-4 me-1">{{ CheckPrice(point) }}</b>원
                <i class="far fa-chevron-right ms-2 text-light fa-xs" v-if="arrow"></i>
                </div>
            </div>

            <button class="btn btn-sm p-2 px-3 bg-dark text-white bg-opacity-25 rounded-3 h-px-50" @click="$refs.registration.pp = true;">
                <i class="fal fa-barcode-read me-2"></i>상품권 등록
            </button>
            </div>
        </div>
        <RegistrationPopup ref="registration"></RegistrationPopup>
    </div>
</template>

<script>
import RegistrationPopup from '@/components/popup/Registration.vue'
export default {
    components:{
        RegistrationPopup
    },
    props:{
        point:{
            type:Number
        },
        arrow:{
            type:Boolean,
            default:true
        }
    },
    methods:{
        async navigateToGiftCard() {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/mypage/giftcard') {
            await this.$router.push('/mypage/giftcard');
            }
        },
        CheckPrice(price){
            
            const amount = price*1;
            if(amount ==0){
                return 0;
            }else{
                return amount.toLocaleString()
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.point_wrap{
    border-radius: 22px;
}
</style>