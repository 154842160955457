<template>
    <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
        <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
        <div class="content bg-body" ref="basic_pp_body">
            <div class="text-end mb-2">
                <button class="btn border-0" @click="pp_off()">
                    <i class="fal fa-times ms-auto text-gray-relative-500"></i>
                </button>
            </div>
            <div class="w-50 mx-auto" v-for="(item,index) in item_info.coupon_info">
                <div class="position-relative ratio ratio-1x1">
                    <div class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center">
                        <img :src="item.image" alt="" width="100%" />
                    </div>
                </div>
                <div class="p-2">
                    <div class="fs-px-13 text-truncate">{{ item.name }}</div>
                    <div class="fs-px-14">
                        <b>{{ CheckPrice(item.price) }}</b> 원
                    </div>
                    <div class="fs-px-14 mb-2">
                        <b>{{ CheckPrice(item.amount) }}</b> 개
                    </div>
                    <hr v-if="item_info.coupon_info.length != (index +1)">
                </div>
            </div>
            <div class="cutting_line border-gray-relative-300 w-100 h-0 mt-2 mb-4"></div>
            <div class="px-3" v-if="item_info.payment_type =='B'">
                <b class="fs-px-14">입금 가상계좌 안내</b>
                <p class="text-gray-relative-500 fs-px-12">
                    구매 전 입금액을 확인해주시기 바랍니다.<br />오후 5시 입금건까지
                    처리되며 이후 입금건은 영업시간에 처리됩니다. 입금자명은 이름과 일치해야 합니다.
                </p>
                <div class="p-3 my-3 border text-center border-gray-relative-200">
                    <div class="d-flex flex-column">
                        <img :src="bank_image" alt="" class="bank_logo" />
                    </div>
                    <div>
                        <h5 class="fw-bold mb-1">{{ bank_account }}</h5>
                        <p>예금주 : {{ bank_holder }}</p>
                    </div>
                </div>
                <p class="text-gray-relative-500 fs-px-12">
                    입금 계좌 정보는 마이페이지의 거래내역에서 다시 확인하실 수 있습니다.
                </p>
      </div>

      <!-- <div
        class="w-100 btn btn-main mt-3 thick"
        @click="OpenCancelPurchaseGift()"
      >
        구매 취소하기
      </div> -->
    </div>

    <CancelPurchaseGift
      ref="CancelPurchaseGift"
      :item_info="item_info"
      :buy_info="buy_info"
    />
  </div>
</template>

<script>
import CancelPurchaseGift from '@/components/popup/CancelPurchaseGift.vue';

const CryptoJS = require('crypto-js');
export default {
  components: {
    CancelPurchaseGift,
  },
  data() {
    return {
      gift_type: 0,
      buy_info: {},

      // front ui
      pp: false,
      off: false,
      timeout_id: null,
      bank_image: '',
      bank_account: '',
      bank_holder: '',
      bank_code: '',
    };
  },
  props: {
    item_info: {
      type: Object,
      default: () => {
        return {
          img: '',
          name: '',
          price: 0,
        };
      },
    },
  },
  mounted() {
    this.GetBankInfo();
  },
  methods: {
    GiftCoupon() {
      const info = this.item_info;
      const type = info.type;
      const code = info.code;
      const price = info.price;
      const bank_account = this.bank_account;
      const bank_holder = this.bank_holder;
      const bank_code = this.bank_code;
      const bank_image = this.bank_image;

      const body = {
        code,
        price,
        bank_account,
        bank_holder,
        bank_code,
        type,
        bank_image,
      };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/shop/GiftTicket', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            this.$alert('상품권 구매 신청하였습니다.').then(() => {
              this.$router.push('/mypage/history');
            });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },

    BuyCoupon() {
      const info = this.item_info;
      const type = info.type;
      const code = info.code;
      const price = info.price;
      const bank_account = this.bank_account;
      const bank_holder = this.bank_holder;
      const bank_code = this.bank_code;
      const bank_image = this.bank_image;

      const body = {
        code,
        price,
        bank_account,
        bank_holder,
        bank_code,
        type,
        bank_image,
      };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/shop/BuyTicket', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            this.$alert('상품권 구매 신청하였습니다.').then(() => {
              this.$router.push('/mypage/history');
            });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },
    CheckPrice(price) {
      const amount = price * 1;
      return amount.toLocaleString();
    },
    GetBankInfo() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/shop/GetBankInfo', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.bank_account = body.info.account;
            this.bank_holder = body.info.holder;
            this.bank_image = body.info.image;
            this.bank_code = body.info.code;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },
    async GetBuyInfo() {
      const code = this.item_info.code;
      const body = {
        code,
      };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      const { data } = await this.$http.post('/front/mypage/GetBuyInfo', {
        req,
      });

      if (data.code == 200) {
        this.buy_info = data.info;
      }
    },

    // front ui
    OpenCancelPurchaseGift() {
      this.GetBuyInfo();
      this.$refs.CancelPurchaseGift.pp = true;
    },
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}
.bank_logo {
  width: 150px;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 10px;
}
.basic_pp .content {
  max-height: 90vh;
}
.ratio-1x1 {
  --bs-aspect-ratio: 45% !important;
}
</style>
