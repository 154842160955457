<template>
  <div class="basic_pp bottom_up" v-if="pp" :class="{ off: off }">
    <div class="dimm bg-body-i bg-opacity-50"></div>
    <div class="content bg-body" ref="basic_pp_body">
      <div v-if="!confirm_cancel">
        <div class="text-end mb-2">
          <button class="btn border-0" @click="pp_off()">
            <i class="fal fa-times ms-auto text-gray-relative-500"></i>
          </button>
        </div>
        <div class="text-center mb-5">
          <h5>상품권 구매를 취소하시겠습니까?</h5>
        </div>
        <div class="w-50 mx-auto">
          <div class="position-relative ratio ratio-1x1">
            <div
              class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center"
            >
              <img :src="item_info.coupon_image" alt="" width="100%" />
            </div>
          </div>
          <div class="p-2 text-center">
            <div class="fs-px-13 text-truncate">
              {{ item_info.coupon_name }}
            </div>
          </div>
        </div>

        <div
          class="cutting_line border-gray-relative-300 w-100 h-0 mt-2 mb-4"
        ></div>
        <div class="px-3">
          <ul>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">구매매수</small>
              <small>{{ buy_info.amount }} 매</small>
            </li>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">판매금액</small>
              <small
                >{{ CheckPrice(buy_info.price * buy_info.amount) }}원</small
              >
            </li>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">할인금액</small>
              <small
                >{{
                  CheckPrice(buy_info.price * buy_info.amount - buy_info.total)
                }}원</small
              >
            </li>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">결제금액</small>
              <small class="fw-bold">{{ CheckPrice(buy_info.total) }}원</small>
            </li>
          </ul>
        </div>

        <div class="w-100 btn btn-main mt-3 thick" @click="CancelBuy()">
          구매 취소하기
        </div>
      </div>

      <div v-if="confirm_cancel">
        <div class="text-center mb-5 pt-4">
          <h5>상품권 구매 취소가<br />정상적으로 처리 되었습니다.</h5>
        </div>
        <div class="w-50 mx-auto">
          <div class="position-relative ratio ratio-1x1">
            <div
              class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center"
            >
              <img :src="item_info.coupon_image" alt="" width="100%" />
            </div>
          </div>
          <div class="p-2 text-center">
            <div class="fs-px-13 text-truncate">
              {{ item_info.coupon_name }}
            </div>
          </div>
        </div>

        <div
          class="cutting_line border-gray-relative-300 w-100 h-0 mt-2 mb-4"
        ></div>
        <div class="px-3">
          <ul>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">구매매수</small>
              <small>{{ buy_info.amount }} 매</small>
            </li>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">판매금액</small>
              <small
                >{{ CheckPrice(buy_info.price * buy_info.amount) }}원</small
              >
            </li>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">할인금액</small>
              <small
                >{{
                  CheckPrice(buy_info.price * buy_info.amount - buy_info.total)
                }}원</small
              >
            </li>
            <li class="flex-between-center">
              <small class="text-gray-relative-600">결제금액</small>
              <small class="fw-bold">{{ CheckPrice(buy_info.total) }}원</small>
            </li>
          </ul>
        </div>

        <div class="w-100 btn btn-main mt-3 thick" @click="ConfirmCancel()">
          구매 취소 확인
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require('crypto-js');

export default {
  data() {
    return {
      item: {
        price: 1000000,
        discount_r: 5,
        amounts: 1,
      },

      // front ui
      pp: false,
      off: false,
      timeout_id: null,
      confirm_cancel: false,
    };
  },
  props: {
    item_info: {
      type: Object,
      default: () => {
        return {
          img: '',
          name: '',
          price: 0,
        };
      },
    },
    buy_info: {
      type: Object,
    },
  },
  mounted() {},
  methods: {
    CheckPrice(price) {
      const amount = price * 1;
      return amount.toLocaleString();
    },
    CancelPurchase() {
      this.confirm_cancel = true;
    },
    ConfirmCancel() {
      // this.confirm_cancel=false;
      // this.pp_off();
      this.$router.go(0);
    },
    async CancelBuy() {
      const code = this.buy_info.code;
      const body = {
        code,
      };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      const { data } = await this.$http.post('/front/mypage/CancelBuy', {
        req,
      });

      if (data.code == 200) {
        this.confirm_cancel = true;
      }
    },

    // front ui
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}
.bank_logo {
  width: 150px;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 10px;
}
.basic_pp .content {
  max-height: 90vh;
}
.ratio-1x1 {
  --bs-aspect-ratio: 45% !important;
}
</style>
