<template>
  <div id="GiftCard" class="bg-gray-relative-100">
    <div class="bg-body">
      <SubHeader :setting="true" :title="'보유 상품권 내역'"></SubHeader>
      <GiftCard class="pb-3" :point="total_price" :arrow="false" />
      <ul class="shop_list p-3 pt-0" v-if="my_item && my_item.length > 0">
        <li
          class="shop_item border border-gray-relative-200"
          v-for="(item, index) in my_item"
          :key="index"
        >
          <div class="ratio ratio-1x1 w-50">
            <div
              class="img_thumbnail border-end d-flex align-items-center justify-content-center px-3"
            >
              <img :src="item.image" alt="" width="100%" />
            </div>
          </div>
          <div class="w-50 p-3 d-flex flex-column justify-content-around">
            <div class="fs-px-13 text-truncate">{{ item.name }}</div>
            <div class="fs-px-12 text-gray-relative-500">{{ item.time }}</div>
            <!-- <div class="text-center"><button class="btn border-0 rounded-2 fs-px-12 w-100 mt-2 bg-gray-relative-300 text-body" @click.prevent="qrFn(item)"><i class="fal fa-qrcode me-1"></i> QR CODE</button></div> -->
            <div class="text-center">
              <button
                class="btn border-0 rounded-2 fs-px-12 w-100 mt-2 bg-gray-relative-300 text-body"
                @click.prevent="giftFn(item)"
              >
                <i class="fal fa-gift me-1"></i> 선물하기
              </button>
            </div>
          </div>
        </li>
      </ul>

      <ul class="shop_list p-3 pt-0" v-if="gift_list.length > 0">
        <li
          class="shop_item border border-gray-relative-200"
          v-for="(item, index) in gift_list"
          :key="item.coupon_code"
        >
          <div class="ratio ratio-1x1 w-50">
            <div
              class="img_thumbnail border-end d-flex align-items-center justify-content-center px-3"
            >
              <img :src="item.image" alt="" width="100%" />
            </div>
          </div>
          <div class="w-50 p-3 d-flex flex-column justify-content-around">
            <div class="fs-px-13 text-truncate">{{ item.name }}</div>
            <div class="fs-px-12 text-gray-relative-500">{{ item.time }}</div>
            <div class="text-center">
              <button
                class="btn btn-main border-0 rounded-2 fs-px-12 w-100 mt-2 text-body"
              >
                <span class="text-white">수령 대기중</span>
              </button>
              <button
                class="btn border-0 rounded-2 fs-px-12 w-100 mt-2 bg-gray-relative-300 text-body"
                @click="handleGiftCancel(item)"
              >
                <span>선물취소</span>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div
        class="p-3 py-6 text-center text-gray-relative-600"
        v-if="my_item.length == 0 && gift_list.length == 0"
      >
        <i class="fal fa-shopping-cart fa-3x d-block my-3"></i>
        <p class="my-2 fs-px-13">
          보유 중인 상품권이 없습니다. <br />상점에서 상품권을 구입할 수
          있습니다.
        </p>
      </div>
    </div>
    <QrPopup ref="qrPopup" :item_info="select_item"></QrPopup>
    <GiftPopup ref="giftPopup" :item_info="select_item"></GiftPopup>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import GiftCard from '@/components/mypage/GiftCardComponent.vue';
import QrPopup from '@/components/popup/QR.vue';
import GiftPopup from '@/components/popup/Gift.vue';
import { format } from '@/mixins';

const CryptoJS = require('crypto-js');

export default {
  components: {
    SubHeader,
    GiftCard,
    QrPopup,
    GiftPopup,
  },
  mixins: [format],
  data() {
    return {
      select_item: {},
      my_item: [],
      total_price: 0,
      gift_list: [],
    };
  },
  mounted() {
    this.GetMemberCoupon();
    this.GetMemberGiftList();
    this.GetMemberTotalCouponPrice();
  },
  methods: {
    GetMemberTotalCouponPrice() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      // this.loading = true;
      this.$http
        .post('/front/mypage/GetMemberTotalCouponPrice', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.total_price = body.price;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                this.$router.push({ path: '/signin' });
              });
            }
          }
        });
    },
    GetMemberCoupon() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      // this.loading = true;
      this.$http
        .post('/front/mypage/GetMemberCouponList', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.my_item = body.list;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                this.$router.push({ path: '/signin' });
              });
            }
          }
        });
    },
    GetMemberGiftList() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.$http
        .post('/front/mypage/GetMemberGiftList', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);

              this.gift_list = body.list;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                this.$router.push({ path: '/signin' });
              });
            }
          }
        });
    },
    async handleGiftCancel(item) {
      const phone = this.phoneFormat(item.phone);
      const coupon_code = item.coupon_code;
      const receiver_name = item.receiver_name;
      const { is_ok } = await this.$confirm(
        `이름: ${receiver_name}<br>발송 번호: ${phone}<br>선물을 취소 하시겠습니까?`
      );

      if (is_ok) {
        const body = { coupon_code };
        const req = CryptoJS.AES.encrypt(
          JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN
        ).toString();

        const { data } = await this.$http.post('/front/mypage/CancelGiftHold', {
          req,
        });

        if (data.code == 200) {
          this.$router.go();
        }
      }
    },
    qrFn(item) {
      this.$refs.qrPopup.pp = true;
      this.select_item = item;
    },
    giftFn(item) {
      this.$refs.giftPopup.pp = true;
      this.select_item = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .shop_item {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    height: 170px;
    .qr_btn {
      width: 100%;
      height: 42px;
      left: initial;
      top: initial;
      right: 0;
      bottom: 0;
    }
  }
}

.ratio-1x1 {
  --bs-aspect-ratio: 50% !important;
}
</style>
