<template>
    <div class="search_list">
        <div class="flex-between-center px-3 my-3">
            <small>총 <b>{{list.length}}</b>개</small>
        </div>
        <div>
            <ul>
                <li v-for="(item, index) in visibleItems" :key="index" class="py-4 bg-body px-3 my-2">
                    <div @click="OpenDetail(index)" class="d-flex align-items-center position-relative">
                        <div class="img_box rounded-4 overflow-hidden position-relative flex-shrink-0 me-3">
                            <v-lazy-image :src="item.image_path"
                            :src-placeholder="require('@/assets/img/Loading_icon.gif')"
                            class="img-bg w-px-90 h-px-90 bg-gray-relative-200 position-relative" alt="..." v-if="item.image_path !=''" />
                            <div class="rounded-4 w-px-90 h-px-90 flex-shrink-0 bg-gray-relative-200 position-relative"  v-if="item.image_path==''"><i class="fas fa-golf-club fa-2x position-absolute top-50 start-50 translate-middle text-gray-relative-400"></i></div>
                        </div>
                        <div class="flex-grow-1 txt_box small w-80">
                            <p class="h6 fw-bold mb-1">{{ item.name }}</p>
                            <p class="lh-sm text-gray-relative-600"><i class="far fa-map-marker-alt me-1"></i>{{ item.address }}</p>
                            <div class="badges mt-2">
                            <ul class="small">
                                <li class="badge rounded-1 me-1 fw-light text-white" :style="`background-color: ${tag.bg}`" v-for="(tag,tab_index) in item.tags" :key="tab_index">{{tag.title}}</li>
                            </ul>
                            </div>
                        </div>
                        <!-- <i class="fa-bookmark align-self-start position-absolute top-0 end-0 bg-body bg-opacity-50" :class="{'fas text-danger':item.bookmark, 'fal text-gray-relative-500':!item.bookmark}" @click.prevent.stop="Bookmark(index)"></i> -->
                    </div>
                </li>
                <!-- <li v-for="(item, index) in list" :key="index" class="py-4 bg-body px-3 my-2">
                    <div @click="OpenDetail(index)" class="d-flex align-items-center position-relative">

                        <v-lazy-image :src="item.image_path" 
                        :src-placeholder="require('@/assets/img/Loading_icon.gif')"
                        class="img-bg rounded-4 w-px-90 h-px-90 flex-shrink-0 me-3 bg-gray-relative-200 position-relative" alt="..." />
                        
                        <i class="fas fa-golf-club fa-2x position-absolute top-50 start-50 translate-middle text-gray-relative-400" v-if="item.image_path==''"></i>
                        <div class="flex-grow-1 txt_box small w-80">
                            <p class="h6 fw-bold mb-1">{{item.name}}</p>
                            <p class="lh-sm text-gray-relative-600"><i class="far fa-map-marker-alt me-1"></i>{{item.address}}</p>
                            <div class="badges mt-2">
                                <ul class="small">
                                    <li class="badge rounded-1 me-1 fw-light text-white" :style="`background-color: ${tag.bg}`" v-for="(tag,tab_index) in item.tags" :key="tab_index">{{tag.title}}</li>
                                </ul>
                            </div>
                        </div>
                        <i class="fa-bookmark align-self-start position-absolute top-0 end-0 bg-body bg-opacity-50" :class="{'fas text-danger':item.bookmark, 'fal text-gray-relative-500':!item.bookmark}" @click.prevent.stop="Bookmark(index)"></i>
                    </div>
                </li> -->
                <li class="empty_data" v-if="list.length == 0">
                    목록이 없습니다.
                </li>
                
                <div v-if="loading" class="loading-indicator text-center py-3">
                    로딩 중...
                </div>
            </ul>
        </div>

		<SearchDetailPopup ref="SearchDetailPopup"  />
		<ReservationPopup ref="ReservationPopup" />
    </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";
import SearchDetailPopup from '@/components/popup/SearchDetail.vue'
import ReservationPopup from '@/components/popup/Reservation.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        ReservationPopup,SearchDetailPopup,VLazyImage
    },
    props: {
        list:{
            type:Array,
            default: function(){
                return [];
            }
        },
    },
    data(){
        return{
            currentPage: 1,
            perPage: 10,
            loading: false,
        }
    },
    computed:{
        visibleItems() {
        return this.list.slice(0, this.currentPage * this.perPage);
        },
    },
    methods:{
        OpenDetail(idx){
            this.$refs.SearchDetailPopup.item = this.list[idx];
            this.$refs.SearchDetailPopup.pp = true;
        },
        Bookmark(index){
            
            const code = this.list[index].code;
            const bookmark = this.list[index].bookmark;
            const body = {code,bookmark};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.list[index].bookmark = !this.list[index].bookmark;
            this.$http.post('/front/search/ChangeFavorites',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );              
            
        },
        handleScroll() {
            const scrollY = window.scrollY;
            const visibleHeight = window.innerHeight;
            const totalHeight = document.documentElement.scrollHeight;

            if (scrollY + visibleHeight >= totalHeight && !this.loading) {
                this.loadMore();
            }
        },
        loadMore() {
            this.loading = true;
                
            setTimeout(() => { // 비동기 데이터 로딩 시뮬레이션
                this.currentPage++;
                this.loading = false;
            }, 1000); // 예시로 1초 후에 데이터를 추가하도록 설정
        },
    },
    
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss" scoped>
    .search_list{
        min-height: calc(100vh - 289px);
    }

    .dark{
        .icon_box .icon_bg{
            opacity: 0.7;
        }
    }
</style>
