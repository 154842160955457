<template>
  <div id="mypage" class="bg-gray-relative-100">
    <div class="bg-body">
      <SubHeader title="MY" :link="'/'" :setting="true"></SubHeader>
      <Profile></Profile>
      <Cash class="pb-4" :point="coupon_total_point"  :cash="cash_total_point" />
      <!-- <GiftCard class="pb-4" :point="coupon_total_point" :cash="cash_total_point" /> -->
    </div>
    <div class="py-1 pt-3">
      <p class="fs-px-12 px-3 text-gray-relative-500">최근 일주일간 내역이 표시됩니다.</p>
      <ul class="my-2 shadow-200 bg-body">
        <!-- <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/mypage/cash_history">
            <span class="fs-px-14">캐쉬 내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li> -->
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/mypage/history">
            <span class="fs-px-14">거래 내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/mypage/reservation">
            <span class="fs-px-14">결제 요청 내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="">
      <ul class="shadow-200 bg-body">
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/cs/notice">
            <span class="fs-px-14">공지사항</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <!-- <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/cs/event">
            <span class="fs-px-14">이벤트</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li> -->
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/cs/faq">
            <span class="fs-px-14">FAQ</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/cs/inquiry/list">
            <span class="fs-px-14">문의내역</span>
            <i class="far fa-chevron-right fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="py-1">
      <ul class="my-2 shadow-200 bg-body">
        <li class="p-3 border-bottom border-gray-relative-100">
          <router-link class="flex-between-center " to="/mypage/setting">
            <span class="fs-px-14">설정</span>
            <i class="far fa-cog fa-xs text-gray-500"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");

import SubHeader from '@/components/common/SubHeader.vue'
import Profile from '@/components/mypage/ProfileComponent.vue'
import Cash from '@/components/mypage/CashComponent.vue'
import GiftCard from '@/components/mypage/GiftCardComponent.vue'
export default {
  components: {
    SubHeader, Profile, GiftCard,Cash
  },
  data(){
    return{
      coupon_total_point : 0,
      cash_total_point :0,
    }
  },
  mounted(){
    this.GetMemberTotalCouponPrice();
    this.GetMemberTotalCashPrice();
  },
  methods: {
    GetMemberTotalCouponPrice(){
            
        const body = {};
        const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
        // this.loading = true;
        this.$http.post('/front/mypage/GetMemberTotalCouponPrice',{req}).then(
            (res) =>  { 
                if(res.status == 200){
                    this.loading = false;
                    if(res.data.code =="200"){
                        const e_body = res.data.body;
                        const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);
                        this.coupon_total_point = body.price;
                    }else if(res.data.code =="9999"){
                        this.$store.dispatch('SETLOGOUT').then(
                            ()=>{
                                this.$router.push({path:'/signin'});
                            }
                        )
                    }
                }
            }
        );
    },
    GetMemberTotalCashPrice(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/mypage/GetMemberTotalCashPrice',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.cash_total_point = body.price;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );
        },
  }
}
</script>

<style lang="scss" scoped>
    #mypage {
        min-height: calc(100vh - 70px) !important;
        padding-bottom: 20px;
    }
</style>