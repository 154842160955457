<template>
    <!-- 취소팝업 -->
    <div class="container">
        <div class="content bg-body">
            <div class="header position-sticky d-flex align-items-center py-2 text-truncate border-bottom">
                <i class="p-2 fal fa-chevron-left" @click="$router.push('/')"></i>
                <h5 class="w-70 text-center text-truncate position-absolute top-50 start-50 translate-middle">상품권 결제 요청 완료</h5>                
            </div>
            <div class="mt-2">
                <div class="flex-between-center py-2 text-truncate">
                    <h5>결제 요청이<br>완료되었습니다.</h5>
                </div>
            </div>
            <div class="body overflow-auto">
                <div class="mt-2">
                    <h6 class="fw-bold mb-3 small">결제 요청 정보</h6>
                    <ul class="small pb-3">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">골프장명</p>
                            <p class="w-100 text-end">{{name}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">라운드일시</p>
                            <p class="w-100 text-end">{{date}} {{ time }}
                            </p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">코스명</p>
                            <p class="w-100 text-end">{{memo}}</p>
                        </li>
                        
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">조건</p>
                            <p class="w-100 text-end" v-if="cart =='Y'">그린피/카트비</p>
                            <p class="w-100 text-end" v-if="cart =='N'">그린피</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">내장인원</p>
                            <p class="w-100 text-end">{{people}}명</p>
                        </li>
                    </ul>
                </div>
                <div class="cutting_line border-gray-relative-300 w-100 h-0"></div>
                <div class="my-4 small">
                    <div class="d-flex justify-content-between mb-2">
                        <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">보유 금액</p>
                        <b class="w-100 text-end">{{CheckPrice(point)}}</b>
                    </div>
                    
                    <div class="d-flex justify-content-between mb-2">
                        <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">최종 결제금액</p>
                        <b class="w-100 text-end">{{CheckPrice(price)}}원</b>
                    </div>
                </div>
            </div>
            <div class="d-flex pt-4 gap-1">
                <button class="btn btn-outline-main thick fs-px-14 w-100" @click="$router.push('/mypage/reservation')">나의 결제요청 내역</button>
                <button class="btn btn-main thick fs-px-14 w-100 border-0" @click="$router.push('/')">홈으로 가기</button>
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import CancelChkPopup from '@/components/popup/CancelChk.vue'

export default {
    components:{
        CancelChkPopup
    },
    data(){
        return{
            name : '',
            date : '',
            time : '',
            memo : '',
            cart : '',
            people : '',

            point: '',
            code : this.$route.params.code,
            price : '',

        }
    },
	methods: {
        CheckPrice(price){
            const amount = price*1;
            return amount.toLocaleString()
        },
        GetReservationInfo(){
            const code = this.code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/reservation/GetReservationInfo',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            this.name = body.l_info.name;
                            this.date = body.info.date;
                            this.time = body.info.confirm_time;
                            this.memo = body.info.memo; 
                            this.cart = body.info.cart;
                            this.people = body.info.people;
                            this.price = body.info.price;
                            this.point = body.point;
                        }
                    }
                }
            )

        },
        comma(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        uncomma(str) {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        },
        inputOnlyNumberFormat() {
            const price = this.reservation_price;
            this.reservation_price = this.comma(this.onlynumber(this.uncomma(price)));
        },
        
        onlynumber(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g,'$1');
        },
        FinalCancel(){
            this.off = false;
            this.$refs.CancelChkPopup.pp = true;
        },
        CloseReserve(){
            this.pp_off();
        },

	},
    
	mounted(){
        this.GetReservationInfo();
	}
}
</script>