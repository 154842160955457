<template>
  <div class="basic_pp top_down" v-show="pp" :class="{'off':off}">
       <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
       <div class="content bg-body text-center">
          <div class="flex-between-center mb-2 ps-3">
              
              <b class="fs-px-14">상품권 등록</b>
              <button class="btn border-0" @click="pp_off()"><i class="fal fa-times ms-auto text-gray-relative-500"></i></button>
          </div>
          <!-- <div class="text-end mb-2">
              <i class="fal fa-times ms-auto text-gray-relative-500 p-2" @click="pp_off()"></i>
          </div>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1024px-QR_code_for_mobile_English_Wikipedia.svg.png" alt="" height="150">
          <p class="text-truncate py-2 w-80 mx-auto">{{}}</p>
           -->
          <div class="p-3 pt-0 text-start">
              <p class="text-gray-relative-500 fs-px-12 mb-3">지류상품권의 핀번호 또는 QR코드를 인식하여 주십시오. 지류 상품권을 모바일 상품권으로 변환합니다.</p>
              <div class="form-group mb-2">
                  <div class="d-flex ">
                      <input class="form-control thick bg-body border-gray-relative-300" type="text" placeholder="핀번호" v-model="code" disabled>
                      <div class="btn btn-secondary flex-shrink-0 small d-flex align-items-center ms-1"  @click="QrScanShow()"><span><i class="fal fa-qrcode"></i></span></div>
                  </div>
              </div>
              <div v-if="qr_area">
                  <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)" ></StreamBarcodeReader>
              </div>
              <button class="btn thick btn-main btn-sm w-100" @click="AddCoupon()">등록</button>
          </div>
      </div>
      <Loading v-if="loading"/>
  </div>
  
</template>

<script>
const CryptoJS = require("crypto-js");

import { StreamBarcodeReader } from "vue-barcode-reader";

import Loading from '@/components/common/Loading.vue'
export default {
  data(){
      return{
          code: '',
          qr_area : false,
          pp: false,
          off: false,
          timeout_id: null,
          loading : false,
      }
  },
  components:{
      StreamBarcodeReader,Loading
  },
  mounted(){
      window.couponview=this;
  },
  props:{
      
  },
  methods:{
      AddCoupon(){
          const code = this.code;
          const body = {code};
          const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
          this.loading = true;
          this.$http.post('/front/mypage/AddCoupon',{req}).then(
              (res) =>  { 
                  if(res.status == 200){
                      this.loading = false;
                      if(res.data.code =="200"){
                          this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">상품권을 추가하였습니다.</p>`).then(()=>{
                              window.location.href = "/mypage/giftcard";
                          })
                          this.pp_off()
                      }else if(res.data.code =="100"){
                          this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-warning"></i><p class="small mt-1">상품권을 핀코드를 확인해주세요.</p>`)
                      }else if(res.data.code =="9999"){
                          this.$store.dispatch('SETLOGOUT').then(
                              ()=>{
                                  this.$router.push({path:'/signin'});
                              }
                          )
                      }
                  }
              }
          );
      },
      QrScanShow(){

          var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

          var target = '';
          if ( varUA.indexOf('android') > -1) {
              target =  "android";
          } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
              target = "ios";
          } else {
              target = "other";
          }

          try {
              if( target =='android') {
                  HybridApp.startQRCode();
              }else if(target =='ios'){
                  let sendData = {};
                  window.webkit.messageHandlers.startQRCode.postMessage(sendData);
              }else{
                  this.qr_area = true;
              }
          }catch(e) {
              console.log("shareText "+e);
          }				
          // this.qr_area = true;
          
      },
      onDecode(result) {
          this.code = result;
          this.qr_area = false;
      },
      async onInit (promise) {
          try {
              await promise
          } catch (error) {
          if (error.name === 'NotAllowedError') {
              this.error = "ERROR: you need to grant camera access permission"
          } else if (error.name === 'NotFoundError') {
              this.error = "ERROR: no camera on this device"
          } else if (error.name === 'NotSupportedError') {
              this.error = "ERROR: secure context required (HTTPS, localhost)"
          } else if (error.name === 'NotReadableError') {
              this.error = "ERROR: is the camera already in use?"
          } else if (error.name === 'OverconstrainedError') {
              this.error = "ERROR: installed cameras are not suitable"
          } else if (error.name === 'StreamApiNotSupportedError') {
              this.error = "ERROR: Stream API is not supported in this browser"
          } else if (error.name === 'InsecureContextError') {
              this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
          } else {
              this.error = `ERROR: Camera error (${error.name})`;
          }
      }
  },
  pp_off(){
          this.off = true;

          if (this.timeout_id) {
              clearTimeout(this.timeout_id);
          }

          this.timeout_id = setTimeout(() => {
              this.pp = false;
              this.off = false;
              if( this.$refs.basic_pp_body ){
                  this.$refs.basic_pp_body.scrollTop = 0;}
              this.timeout_id = null;
          }, 200);
      }
  }

}
</script>
<style lang="scss" scoped>
  .cutting_line{
  border-top: 1px dashed;
  }
  .bank_logo{
  width: 150px;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 10px;
  }
  .basic_pp .content{
  max-height: 90vh;
  }
</style>