<template>
  <div id="history" class="bg-gray-relative-100">
    <div class="pb-3">
      <SubHeader title="나의 결제 요청 내역"></SubHeader>
    </div>
    <ul
      class="category flex-between-center fs-px-13 gap-2 fw-bold rounded-pill bg-gray-relative-200 mx-3 mb-2"
    >
      <li
        class="w-33 text-center rounded-pill py-2"
        @click="ChangeCategory('ALL')"
        :class="{ 'bg-main text-white': type === 'ALL' }"
      >
        전체
      </li>
      <li
        class="w-33 text-center rounded-pill p-2"
        @click="ChangeCategory('PAY')"
        :class="{ 'bg-main text-white': type === 'PAY' }"
      >
        결제요청내역
      </li>
      <li
        class="w-33 text-center rounded-pill p-2"
        @click="ChangeCategory('CANCEL')"
        :class="{ 'bg-main text-white': type === 'CANCEL' }"
      >
        취소요청내역
      </li>
    </ul>
    <div class="px-3">
      <!-- 재검토 : 이미 위에서 category 분류를 하고 있어서 select로 또 나눌 필요가 있을지 -->
      <div class="mb-2 d-flex justify-content-end">
        <select
          class="w-33 form-select form-select-sm bg-body border-gray-relative-300 text-body"
          v-model="status"
          @change="ChangeStatus()"
        >
          <option
            class="text-body"
            v-for="(item, index) in status_list"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <!-------------------------------------------------------->
      <ul v-if="list && list.length > 0">
        <li
          class="p-3 mb-3 bg-body rounded-4 shadow-400"
          v-for="(item, index) in list"
          :key="index"
          @click="GoToPayment(item)"
        >
          <div class="fs-px-14 d-flex justify-content-between mb-2">
            <div class="flex-shrink-0">
              <small class="text-gray-relative-500" v-if="item.type == 'D'">
                {{ addDay(item.update_time) }}
                <!-- (예약인원 : {{ item.people }}명 )-->
              </small>
              <small class="text-gray-relative-500" v-if="item.type == 'R'">
                <!-- {{ item.date }} -->
                <span v-if="item.status != 'H'">
                  <!-- {{ item.confirm_time }} -->
                  <!-- (예약인원 : {{ item.people }}명 )-->
                </span>
                <span v-if="item.status == 'H'"
                  >{{ item.start_time }} ~ {{ item.end_time }}
                  <!-- (예약인원 : {{ item.people }}명 )-->
                </span>
              </small>
            </div>
            <div class="text-end">
              <span
                class="badge rounded-1 me-1 fw-light fs-px-11 gradient-success"
                v-if="item.type == 'D'"
                >직접 예약</span
              >
              <!-- 예약완료>취소요청 예약대기>결제요청 예약 취소>취소 완료 -->
              <span
                class="badge rounded-1 me-1 fw-light fs-px-11"
                :class="{
                  'gradient-success': item.status === 'Y',
                  'bg-primary': item.status === 'P',
                  'bg-gray-500': item.status === 'H',
                  'gradient-danger': item.status === 'C',
                  'bg-warning': item.status === 'CP',
                }"
              >
                {{
                  item.status === 'Y'
                    ? '결제 완료'
                    : item.status === 'H'
                    ? '결제 대기'
                    : item.status === 'P'
                    ? '추가 결제 요청'
                    : item.status === 'CP'
                    ? '취소 요청'
                    : '취소 완료'
                }}</span
              >
              <!-- <span class="badge rounded-1 me-1 fw-light fs-px-11" :class="{'gradient-success':item.status==='Y','bg-primary':item.status==='P','bg-gray-500':item.status==='H','gradient-danger':item.status==='C'}">{{ item.status==='Y'?'예약 완료':item.status==='H'?'예약 대기':item.status==='P'?'결제 요청':'예약 취소' }}</span> -->
            </div>
          </div>
          <div class="w-100 flex-between-center">
            <div
              class="bg-img w-px-70 h-px-70 rounded-4 shadow flex-shrink-0"
              :style="{ backgroundImage: 'url(' + item.image_path + ')' }"
            ></div>
            <div class="flex-grow-1 txt_box small w-80 ps-3">
              <p class="h6 fw-bold mb-1">{{ item.name }}</p>
              <p class="lh-sm text-gray-relative-600">
                <i class="far fa-map-marker-alt me-1 mb-2"></i
                >{{ item.address }}
              </p>
              <p class="lh-sm text-gray-relative-600">
                <i class="far fa-clock me-1 mb-1"></i>
                {{ addDay(item.date) }}
                {{ item.confirm_time }}
              </p>
              <p class="fw-bold small text-success lh-lg">
                {{ item.proxy_name }} <span v-if="item.people > 1">외 {{ item.people - 1 }} 명</span>
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div class="p-3 py-6 text-center text-gray-relative-600" v-else>
        <i class="fal fa-calendar-alt fa-3x d-block my-3"></i>
        <p class="my-2 fs-px-13">요청 내역이 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import { format } from '@/mixins';
// import Profile from '@/components/mypage/ProfileComponent.vue'
// import GiftCard from '@/components/mypage/GiftCardComponent.vue'
const CryptoJS = require('crypto-js');
export default {
  components: {
    SubHeader,
  },
  mixins: [format],
  data() {
    return {
      type: 'ALL',
      status: '',

      status_list: [
        {
          value: '',
          name: '전체',
        },
        {
          value: 'H',
          name: '결제대기',
        },
        {
          value: 'Y',
          name: '결제완료',
        },
        {
          value: 'P',
          name: '결제요청',
        },
        {
          value: 'CP',
          name: '취소요청',
        },
        {
          value: 'C',
          name: '취소완료',
        },
      ],
      list: [],
    };
  },
  mounted() {
    this.GetReservationList();
  },
  methods: {
    GetReservationList() {
      const type = this.type;
      const status = this.status;
      const body = { type, status };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      // this.loading = true;
      this.$http
        .post('/front/mypage/GetReservationList', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.list = body.list;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                this.$router.push({ path: '/signin' });
              });
            }
          }
        });
    },
    GoToPayment(item) {
      this.$router.push({ path: `/mypage/reservation/${item.r_code}` });
      // 결제 로직 상세페이지로 옮김

      // const status = item.status;
      // const code = item.r_code;
      // if(status =='P'){
      //   this.$alert("결제 진행하시겠습니까?").then(
      //     ()=>{
      //       this.$router.push({path:`/payment/${code}`});
      //     }
      //   )
      // }else if(status =='Y'){
      //   this.$router.push({path:`/receipt/${code}`});
      // }else if(status =='C'){
      //   this.$router.push({path:`/receipt/${code}`});
      // }
    },
    ChangeCategory(type) {
      if (type == 'ALL') {
        this.status_list = [
          {
            value: '',
            name: '전체',
          },
          {
            value: 'H',
            name: '결제대기',
          },
          {
            value: 'P',
            name: '결제요청',
          },
          {
            value: 'Y',
            name: '결제완료',
          },
          {
            value: 'CP',
            name: '취소요청',
          },

          {
            value: 'C',
            name: '취소완료',
          },
        ];
      } else if (type == 'PAY') {
        this.status_list = [
          {
            value: '',
            name: '전체',
          },
          {
            value: 'H',
            name: '결제대기',
          },
          {
            value: 'P',
            name: '결제요청',
          },
          {
            value: 'Y',
            name: '결제완료',
          },
        ];
      } else if (type == 'CANCEL') {
        this.status_list = [
          {
            value: '',
            name: '전체',
          },
          {
            value: 'CP',
            name: '취소요청',
          },

          {
            value: 'C',
            name: '취소완료',
          },
        ];
      }
      this.status = '';
      this.type = type;

      this.GetReservationList();
    },
    ChangeStatus() {
      this.GetReservationList();
    },
    DateForm() {
      let days = ['일', '월', '화', '수', '목', '금', '토'];
      let date = new Date();
      let reservation_confirm_hour = 10;
      let reservation_confirm_minute = 0;

      let y = date.getFullYear();
      let m = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
      let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let day = date.getDay();

      let hh =
        reservation_confirm_hour < 10
          ? '0' + reservation_confirm_hour
          : reservation_confirm_hour;
      let mm =
        reservation_confirm_minute < 10
          ? '0' + reservation_confirm_minute
          : reservation_confirm_minute;

      return (
        y +
        '. ' +
        (m + 1) +
        '. ' +
        d +
        '(' +
        days[day] +
        ')' +
        ' ' +
        hh +
        ':' +
        mm
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#history {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
}
.category {
  padding: 3px;
}
.gradient-danger {
  background: linear-gradient(to bottom, #fc6e51, #e9573f);
}
.gradient-success {
  background: linear-gradient(to bottom, #a0d468, #8cc152);
}
</style>
