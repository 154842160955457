<template>
    <div class="basic_pp bottom_up" v-show="pp"  :class="{'off':off}">
        <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
        <div class="content bg-body text-center" ref="basic_pp_body">
            <div class="text-center fw-bold mb-3">
                선물 발송 완료
            </div>
            
            <div class="p-3">
                <img src="@/assets/img/gift2.png" alt="" height="100" class="mb-4">
                <!-- <img src="@/assets/img/gift.png" alt="" height="70" style="filter:hue-rotate(165deg);transform:rotate(45deg)" class="position-absolute"> -->
                <p style="word-break: keep-all;">
                    {{sender_name}} 님께서 {{info.name}}님으로 보내신 선물이
                    정상적으로 발송되었습니다.
                </p>

            </div>
            
            <div class="py-2"><span class="badge fs-4 btn-outline-main">{{info.coupon_name}}</span></div>
            <div class="text-center my-3"><button class="btn btn-main thick fs-px-14 w-100 border-0" @click="$router.push({path:'/mypage/history'})">선물하기 확인</button></div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
            pp: false,
            off: false,
            timeout_id: null,
            sender_name : this.$store.state.name,

        }
    },
    components: {
        
    },
    props:{
        info:{
            type:Object,
            default: () => {
                return{
                    name:'',
                    phone: '',
                    coupon_name : '',
                }
            }
        }
    },
    mounted(){
        
    },
    methods:{
        pp_off(){
            this.off = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }
            
            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;
                }
                
                this.timeout_id = null;
            }, 200);
        }
    }
}
</script>