<template>
    <div class="px-3">
        <div class="point_wrap bg-main ">
            <div class="d-flex flex-column">
                <button class="add_btn bg-dark p-3 btn btn-sm border-0 p-0 text-white bg-opacity-25 rounded-0" @click="$refs.registration.pp = true;">
                    <i class="fal fa-barcode-read me-2"></i>상품권 등록
                </button>
                <div class="p-4 border-top hr-line">
                    <div class="flex-between-center" @click="navigateToGiftCard();">
                        <small class="text-white">보유 상품권</small>
                        <div class="d-flex align-items-center text-white">
                            <b class="fs-4 me-1">{{ CheckPrice(point) }}</b>원
                            <i class="far fa-chevron-right ms-2 text-light fa-xs" v-if="arrow"></i>
                        </div>
                    </div>
                    <div class="flex-between-center" @click="navigateToCash();">
                        <small class="text-white">보유 캐쉬</small>
                        <div class="d-flex align-items-center text-white">
                            <b class="fs-4 me-1">{{ CheckPrice(cash) }}</b>원
                            <i class="far fa-chevron-right ms-2 text-light fa-xs" v-if="arrow"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegistrationPopup ref="registration"></RegistrationPopup>
    </div>
</template>

<script>
import RegistrationPopup from '@/components/popup/Registration.vue'
export default {
    components:{
        RegistrationPopup
    },
    props:{
        point:{
            type:Number
        },
        cash:{
            type:Number
        },
        arrow:{
            type:Boolean,
            default:true
        }
    },
    methods:{
        async navigateToGiftCard() {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/mypage/giftcard') {
            await this.$router.push('/mypage/giftcard');
            }
        },
        async navigateToCash() {
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/mypage/cash_history') {
            await this.$router.push('/mypage/cash_history');
            }
        },
        CheckPrice(price){
            const amount = price*1;
            if(amount ==0){
                return 0;
            }else{
                return amount.toLocaleString()
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.point_wrap{
    overflow: hidden;
    border-radius: 16px;
    .add_btn {
    }
    .hr-line{
        border-color: #cdcdcd4d !important;
    }
}
</style>