<template>
    <div class="profile p-3 pt-0" v-if="$store.state.login" @click="$router.push('/mypage/profile')">
      <div class="d-flex gap-3 align-items-center" v-if="profile_path !=''">
        <img :src="profile_path" alt="avatar" class="avatar rounded-circle"  v-if="profile_path != default_image">
        <img src="@/assets/img/profile_default.svg" alt="avatar" class="avatar rounded-circle" v-else>
        <div>
            <h6>{{ name }}</h6>
            <small class="text-gray-relative-500">{{ email }}</small>
        </div>
      </div>
      <i class="far fa-chevron-right fa-xs"></i>
    </div>
    <div class="profile p-3 pt-0" v-else @click="$router.push('/login')">
      <div class="d-flex gap-3 align-items-center" >
        <img src="@/assets/img/profile_default.svg" alt="avatar" class="avatar rounded-circle">
        <small>로그인하고 다양한 혜택을 누려보세요!</small>
      </div>
      <i class="far fa-chevron-right fa-xs"></i>
    </div>
    
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
          default_image : '/assets/img/profile_default.svg',
          profile_path : '',
          email:'',
          name:''
        }
    },
    mounted(){
      this.GetProfileInfo();
    },
    methods:{
      GetProfileInfo(){
          const body = {};
          const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
          // this.loading = true;
          this.$http.post('/front/mypage/GetProfileInfo',{req}).then(
              (res) =>  { 
                  if(res.status == 200){
                      this.loading = false;
                      if(res.data.code =="200"){
                          const e_body = res.data.body;
                          const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                          const d_res = bytes.toString(CryptoJS.enc.Utf8);
                          const body = JSON.parse(d_res)
                          this.name = body.info.name;
                          this.email = body.info.email;
                          
                          this.profile_path = this.default_image;
                          if(body.info.profile_path !=''){
                              this.profile_path = body.info.profile_path;
                          }
                          
                      }else if(res.data.code =="9999"){
                          this.$store.dispatch('SETLOGOUT').then(
                              ()=>{
                                  this.$router.push({path:'/signin'});
                              }
                          )
                      }
                  }
              }
          );                
      },
    }
}
</script>
<style lang="scss" scoped>
.profile{
    display: flex;
    align-items: center;
    justify-content: space-between;
  .avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

</style>