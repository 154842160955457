<template>
    <div id="changePassword" class="bg-gray-relative-100">
        <div class="bg-body">
            <SubHeader title="비밀번호 변경" :alert="false" :bookmark="false"></SubHeader>
        </div>
        <div class="container">
            <p class="small mx-3 py-3">안전한 회원정보 수정을 위해,<br>
현재 사용중인 비밀번호를 입력 후 변경이 가능합니다</p>
            <form>
                <div class="px-3">
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 기존 비밀번호를 입력해주세요.</label>
                        <input class="form-control py-12p" type="password" required placeholder="기존 비밀번호를 입력해주세요." v-model="old_password">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 새로운 비밀번호를 입력해주세요.</label>
                        
                        <input class="form-control py-12p mb-2" type="password" required placeholder="새 비밀번호" v-model="new_password" @change="CheckMatchPassword()">
                        <input class="form-control py-12p" type="password" required placeholder="새 비밀번호 확인" v-model="new_password_confirm" @change="CheckMatchPassword()">
                    </div>
                    <p class="text-danger mb-3 small" v-if="pw_match_chk===false">
                        <span class="small">- 비밀번호가 일치하지 않습니다</span>
                    </p>
                </div>
            
                <div class="validate_box bg-body rounded border m-3 p-3">
                    <h6 class="fw-bold mb-3 fs-px-14">비밀번호 설정 필수 조건</h6>
                    <ul class="small">
                        <li :class="{'text-main':error[0],'text-muted':!error[0]}">영문 기준 6자 이상</li>
                        <li :class="{'text-main':error[1],'text-muted':!error[1]}">3자리 이상 연속된 영문, 숫자 미사용</li>
                        <li :class="{'text-main':error[2],'text-muted':!error[2]}">특수문자 조합</li>
                        <li :class="{'text-main':error[3],'text-muted':!error[3]}">영문, 숫자 조합</li>
                    </ul>
                </div>
                
            </form>
            <div class="w-100 p-3">
                <button @click="ChangePassword()" class="btn thick bg-secondary text-white mt-2 w-100">비밀번호 변경하기</button>
            </div>
        </div>
        <LoginCheck/>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
import LoginCheck from '@/components/common/LoginCheck.vue'
export default {
    components:{
        SubHeader,LoginCheck
    },
    data(){
        return{
            title:'비밀번호 변경하기',
            old_password: '',
            new_password:'',
            new_password_confirm:'',
            code :this.$store.state.code,
            token :this.$store.state.token,
            email: this.$store.state.email,
            error:[false,false,false,false],
            //------

            pw_match_chk: null,
            alart_memo :'',
            
        }
    },
    computed:{
    },
    watch: {
        password(newVal) {
            this.checkPassword(newVal);
        }
    },
    methods:{
        CheckMatchPassword(){
            const new_password = this.new_password;
            const new_password_confirm = this.new_password_confirm;
            this.checkPassword(new_password)
            if(new_password !=new_password_confirm){
                this.pw_match_chk = false;
            }else{
                this.pw_match_chk = true;
            }
        },
        checkPassword(pwVal) {
            let password = pwVal.toString();
            const passwordRegex = /[!@#$%^&*(),.?":{}|<>]/;
            if (pwVal.length >= 3) {
                const regex = /(.)\1\1/i;
                if (!regex.test(pwVal)) {
                    this.error[1] = false;
                }else{
                    this.error[1] = true;
                }
            }else{
                this.error[1] = false;
            }
            if (pwVal.length >= 8) {
                this.error[0] = true;
            }else{
                this.error[0] = false;
            }
            if (passwordRegex.test(pwVal)) {
                this.error[2] = true;
            }else{
                this.error[2] = false;
            }
            if (this.isNumStr(password)) {
                this.error[3] = true;
            }else{
                this.error[3] = false;
            }
        },
        isNumStr(value){
            let hasLetter = false;
            let hasNumber = false;
            for (let char of value) {
                if (isNaN(char * 1)) {
                hasLetter = true;
                } else {
                hasNumber = true;
                }
            }
            if (!hasLetter || !hasNumber) {
                return false;
            }else{
                return true;
            }
        },
        ChangePassword(){

            if(!this.pw_match_chk){
                this.$alert('변경하실 비밀번호를 확인해주세요')
                return false;
            }
            
            

            const old_password = this.old_password;
            const new_password = this.new_password;
            const body = {old_password , new_password};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/front/mypage/ChangePassword', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push('/signin');
                                }
                            )
                        }else if(res.data.code=='100'){
                            this.$alert("기존 비밀번호를 확인해주세요.");
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push('/signin');
                                }
                            )
                        }
                    }
                }
            )
        }
    },
}
</script>
<style lang="scss" scoped>

#changePassword {
    min-height: calc(100vh - 70px) !important;
    padding-bottom: 20px;
}
</style>