<template>
    <div v-show="pp">
        <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
            <div class="dimm bg-body-i bg-opacity-50" @click="CloseReserve()"></div>
            <div class="content bg-body">
                <div class="header position-sticky flex-between-center py-2 text-truncate">
                    <h5>결제 요청을<br />취소하시겠습니까?</h5>
                    <i class="p-2 fal fa-times" @click="CloseReserve()"></i>
                </div>
                <div class="body pt-1" ref="basic_pp_body">
                    <div class="cutting_line border-gray-relative-300 w-100 h-0 mb-4"></div>
                    <div class="mt-2">
                        <h6 class="fw-bold mb-3 small">취소 요청 정보</h6>
                        <ul class="small pb-3">
                            <li class="d-flex justify-content-between mb-2">
                                <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">골프장명</p>
                                <p class="w-100 text-end">{{ item.name }}</p>
                            </li>
                            <li class="d-flex justify-content-between mb-2">
                                <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">라운드일시</p>
                                <p class="w-100 text-end">{{ addDay(item.date) }} {{ item.time }}</p>
                            </li>
                            <li class="d-flex justify-content-between mb-2">
                                <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">코스명</p>
                                <p class="w-100 text-end">{{ item.memo }}</p>
                            </li>
                            <li class="d-flex justify-content-between mb-2">
                                <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">조건</p>
                                <p class="w-100 text-end">
                                    <span class="ps-2">
                                        그린피
                                        <span v-show="item.cart == 'Y'">/ 카트비</span>
                                        <span v-show="item.other == 'Y'">/ {{ item.other_memo }}</span>
                                    </span>
                                </p>
                            </li>
                            <li class="d-flex justify-content-between mb-2">
                                <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">내장인원</p>
                                <p class="w-100 text-end">{{ item.people }}명</p>
                            </li>
                        </ul>
                    </div>
                    <div class="cutting_line border-gray-relative-300 w-100 h-0"></div>
                    <div class="my-4 small">
                        <div class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">결제 방법</p>
                            <b class="w-100 text-end">{{ item.payment_name }}</b>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-700 me-3 w-px-80">최종 결제금액</p>
                            <b class="w-100 text-end">{{ CheckPrice(item.price) }}원</b>
                        </div>
                    </div>
                    <div class="cutting_line border-gray-relative-300 w-100 h-0 my-4"></div>
                    <div>
                        <div class="mt-1 lh-sm px-1">
                            <h6 class="small fw-bold mb-2">취소 주의사항</h6>
                            <small>취소 시 상품권과 캐쉬는 반환되며, 이벤트 관련된 쿠폰 및 캐쉬는 반환되지 않습니다.</small>
                        </div>
                    </div>
                </div>
                <div class="d-flex pt-4 gap-1">
                    <button class="btn btn-outline-main thick fs-px-14 w-100" @click="pp_off()">닫기</button>
                    <button class="btn btn-main thick fs-px-14 w-100 border-0" @click="FinalCancel()">결제 요청 취소</button>
                </div>
            </div>
        </div>
        <CancelChkPopup ref="CancelChkPopup" :status="status" />
    </div>
</template>

<script>
const CryptoJS = require('crypto-js');
import CancelChkPopup from '@/components/popup/CancelChk.vue';
import { format } from '@/mixins';

export default {
    components: {
        CancelChkPopup,
    },
    // props: {
    //     status: String,
    // },
    mixins: [format],
    data() {
        return {
            status : '',
            item: {},
            reserve_people: '',
            reserve_start_time: '',
            reserve_end_time: '',
            pp: false,
            off: false,
            timeout_id: null,
        };
    },
    methods: {
        CheckPrice(price) {
            const amount = price * 1;
            return amount.toLocaleString();
        },
        comma(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        uncomma(str) {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        },
        inputOnlyNumberFormat() {
            const price = this.reservation_price;
            this.reservation_price = this.comma(this.onlynumber(this.uncomma(price)));
        },
        onlynumber(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1');
        },
        FinalCancel() {
            this.off = false;
            this.$refs.CancelChkPopup.status = this.item.status;
            this.$refs.CancelChkPopup.pp = true;
        },
        CloseReserve() {
            this.pp_off();
        },
        pp_off() {
            this.off = true;
            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if (this.$refs.basic_pp_body) {
                    this.$refs.basic_pp_body.scrollTop = 0;
                }
                this.timeout_id = null;
            }, 200);
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}

.vc-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  background-color: transparent;
}
</style>
