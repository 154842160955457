<template>
    <div v-show="pp">
        <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
            <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
            <div class="content bg-body p-0">
                <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
                    <div class="receipt">
                        <div class="d-flex">
                            <span class="text-body text-opacity-50" role="button" @click="pp_off()">
                                <i class="me-3 far fa-chevron-left"></i>
                            </span>
                            <span class="btn btn-sm ms-auto bg-body shadow-200 mb-3" @click="SendReceipt">영수증 다운로드</span>
                        </div>

                        <div class="bg-body p-3 shadow-200 rounded-3 pb-4" ref="receipt">
                            <div class="py-4 text-center">
                                <h4 class="fw-bold">상품권 매출전표</h4>
                            </div>
                            <div>
                                <div class="border border-gray-relative-700"></div>
                                <h5 class="py-2">예약 정보</h5>
                                <div class="cutting_line border-gray-relative-500 pb-4"></div>
                            </div>
                            <ul class="small">
                                <li class="d-flex justify-content-between mb-2">
                                    <p class="flex-shrink-0 text-gray-relative-500 me-3">예약일</p>
                                    <p class="w-100 text-end">
                                        {{ e_r_date }}
                                    </p>
                                </li>
                                <li class="d-flex justify-content-between mb-2">
                                    <p class="flex-shrink-0 text-gray-relative-500 me-3">예약시간</p>
                                    <p class="w-100 text-end">{{ r_time }}</p>
                                </li>
                                <li class="d-flex justify-content-between mb-2">
                                    <p class="flex-shrink-0 text-gray-relative-500 me-3">예약인원</p>
                                    <p class="w-100 text-end" v-if="r_people != ''">
                                        {{ r_people }} 명
                                    </p>
                                </li>
                                <li class="d-flex justify-content-between mb-2" v-if="r_memo != ''">
                                    <p class="flex-shrink-0 text-gray-relative-500 me-3">예약정보</p>
                                    <p class="w-100 text-end">{{ r_memo }}</p>
                                </li>
                                <li class="d-flex justify-content-between mb-2">
                                    <p class="flex-shrink-0 text-gray-relative-500 me-3">매장정보</p>
                                    <p class="w-100 text-end">
                                        {{ r_name }}<span v-if="r_text != ''">({{ r_text }})</span>
                                    </p>
                                </li>
                            </ul>
                            <div class="mt-5">
                                <div class="border border-gray-relative-700"></div>
                                <h5 class="py-2">결제정보</h5>
                                <div class="cutting_line border-gray-relative-500 pb-4"></div>
                            </div>

                            <div class="">
                                <ul class="small">
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">구매상품</p>
                                        <div>
                                            <p class="w-100 text-end" v-for="(item, index) in item_list" :key="index">
                                                {{ item.name }}
                                            </p>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">결제수단</p>
                                        <p class="w-100 text-end">{{ CheckPaymentType(payment_type) }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">거래상태</p>
                                        <p class="w-100 text-end">{{ CheckStatus(status) }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">거래일시</p>
                                        <p class="w-100 text-end">{{ create_time }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2" v-if="status == 'C'">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">취소일시</p>
                                        <p class="w-100 text-end text-danger">{{ update_time }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">승인금액</p>
                                        <p class="w-100 text-end">{{ total.toLocaleString() }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between pt-2 px-2 pb-2 bg-gray-relative-100" v-if="total != ''">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">
                                            ┕ 과세금액
                                        </p>
                                        <p class="w-100 text-end">{{ (total * 0.9).toLocaleString() }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between pb-2 px-2 mb-2 bg-gray-relative-100" v-if="total != ''">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">┕ 부가세</p>
                                        <p class="w-100 text-end">{{ (total * 0.1).toLocaleString() }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2" v-if="payment_type == 'C'">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">
                                            상품권 권종
                                        </p>
                                        <div class="w-100 text-end">
                                            <div v-for="(p_item, p_index) in payment_name.split('||')" :key="p_index">{{ p_item }}</div>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2" v-if="payment_type == 'C'">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">
                                            상품권 핀번호
                                        </p>
                                        <div class="w-100 text-end">
                                            <div v-for="(p_item, p_index) in payment_target.split('||')" :key="p_index">{{ p_item }}</div>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2" v-if="payment_type == 'CP'">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">결제정보</p>
                                        <div class="w-100 text-end">
                                            <div v-for="(p_item, p_index) in payment_name.split('||')" :key="p_index">
                                                {{ p_item }}
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2" v-if="payment_type == 'CP'">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">결제코드</p>
                                        <div class="w-100 text-end">
                                            <div v-for="(p_item, p_index) in payment_target.split('||')" :key="p_index">
                                                {{ p_item }}
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2" v-if="payment_type == 'P'">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">승인코드</p>
                                        <p class="w-100 text-end">{{ payment_target }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">고객명</p>
                                        <p class="w-100 text-end">{{ user_name }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-4">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">고객전화번호</p>
                                        <p class="w-100 text-end">{{ phoneFormat(user_phone) }}</p>
                                    </li>
                                </ul>
                                <div class="mt-5">
                                    <div class="border border-gray-relative-700"></div>
                                    <h5 class="py-2">공급자 정보</h5>
                                    <div class="cutting_line border-gray-relative-500 pb-4"></div>
                                </div>
                                <ul class="small">
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">상호</p>
                                        <p class="w-100 text-end">{{ shop_name }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">대표자명</p>
                                        <p class="w-100 text-end">{{ shop_owner }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">전화번호</p>
                                        <p class="w-100 text-end">{{ phoneFormat(shop_tel) }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">사업자번호</p>
                                        <p class="w-100 text-end">{{ business_number }}</p>
                                    </li>
                                    <li class="d-flex justify-content-between mb-2">
                                        <p class="flex-shrink-0 text-gray-relative-500 me-3">주소</p>
                                        <p class="w-100 text-end">{{ shop_address }}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="flex-between-center mt-4">
                            <button class="form-control thick bg-gray-relative-300 border-gray-relative-300 w-60 fs-6 text-center me-2" @click="Cancel()" v-if="r_status == 'CP'">결제 취소</button>
                            <button @click="pp_off()" class="btn thick btn-main w-100 text-white">확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toBlob } from 'html-to-image';
const CryptoJS = require('crypto-js');
import { format } from '@/mixins';

export default {
    // props: {
    //     status: String,
    // },
    mixins: [format],
    data() {
        return {
            payment_code: this.$route.params.code,
            item_list: [],
            shop_name: '',
            shop_owner: '',
            shop_tel: '',
            business_number: '',
            shop_address: '',
            user_name: '',
            user_phone: '',
            total: '',
            payment_type: '',
            create_time: '',
            update_time: '',
            status: '',
            payment_target: '',
            cancel_able: 'N',
            r_date: '',
            r_time: '',
            r_name: '',
            r_people: '',
            r_text: '',
            r_memo: '',
            r_status: '',
            e_r_date: '',
            non_payment: false,
            pp: false,
            off: false,
            timeout_id: null,
        };
    },
  methods: {
    async SendReceipt() {
      const receiptRef = this.$refs.receipt;
      const blob = await toBlob(receiptRef);
      const file = new File(
        [blob],
        `${this.user_name}_${this.update_time}_receipt.png`
      );
      const fileName = new Date().getTime() + file.name;
      const email = this.$cookies.get(process.env.VUE_APP_ID_KEY);

      // 임시 파일 다운로드
      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    ChangeDate() {
      const r_date = this.e_r_date;
      const b_r_date = this.r_date;
      this.$confirm(
        `예약일 변경 <br>${b_r_date} -> ${r_date} <br> 변경 하시겠습니까?`
      ).then((result) => {
        if (result.isOk) {
          this.ChangeReservationDate();
        } else {
          this.e_r_date = this.r_date;
        }
      });
    },
    ChangeReservationDate() {
      const r_date = this.e_r_date;
      const code = this.payment_code;
      const body = { code, r_date };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/ChangeReservationDate', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              this.$alert('예약일을 변경하였습니다.');
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                window.location.href = '/signin';
              });
            }
          }
        });
    },
    CheckStatus(status) {
      if (status == 'Y') {
        return '승인';
      } else if (status == 'C') {
        return '취소';
      }
    },
    CheckPaymentType(type) {
      if (type == 'P') {
        return '캐쉬';
      } else if (type == 'C') {
        return '지류상품권';
      } else if (type == 'CP') {
        return '지류상품권 & 캐쉬';
      }
    },
    GetReceipt() {
      const payment_code = this.payment_code;

      const body = { payment_code };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/front/payment/GetReceipt', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            if (body.non_payment) {
              this.non_payment = body.non_payment;
              this.$alert('결제하지 않은 예약 건 입니다.').then(
                ()=>{
                    this.pp_off();
                }
                
              );
              return;
            }
            this.item_list = body.item_list;
            this.cancel_able = body.info.cancel_able;
            this.user_name = body.info.user_name;
            this.user_phone = body.info.user_phone;

            this.payment_type = body.info.payment_type;
            this.payment_target = body.info.payment_target;
            this.payment_name = body.info.payment_name;

            this.total = body.info.total * 1;

            this.shop_name = body.info.shop_name;
            this.shop_owner = body.info.shop_owner;
            this.shop_tel = body.info.shop_tel;
            this.business_number = body.info.business_number;
            this.shop_address = body.info.shop_address;
            this.status = body.info.status;
            this.cancel_able = body.info.cancel_able;
            this.create_time = body.info.create_time;
            this.update_time = body.info.update_time;

            this.r_date = body.reservation_info.date;
            this.e_r_date = body.reservation_info.date;
            this.r_time = body.reservation_info.confirm_time;
            this.r_people = body.reservation_info.people;
            this.r_name = body.reservation_info.name;
            this.r_text = body.reservation_info.location_name;
            this.r_memo = body.reservation_info.memo;
            this.r_status = body.reservation_info.status;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
    Cancel() {
      // this.$confirm('결제를 취소하시겠습니까?').then((result) => {
      //   if (result.isOk) {
      //     const payment_code = this.payment_code;

      //     const body = { payment_code };
      //     const req = CryptoJS.AES.encrypt(
      //       JSON.stringify(body),
      //       process.env.VUE_APP_SECRET_TOKEN
      //     ).toString();
      //     this.loading = true;
      //     this.$http
      //       .post('/shop/payment/CancelPayment', { req })
      //       .then((res) => {
      //         if (res.status == 200) {
      //           this.loading = false;
      //           if (res.data.code == '200') {
      //             this.$alert('결제를 취소했습니다').then(() => {
      //               this.$router.push('/mypage');
      //             });
      //           } else if (res.data.code == '9999') {
      //             this.$store.dispatch('SETLOGOUT').then(() => {
      //               window.location.href = '/signin';
      //             });
      //           }
      //         }
      //       });
      //   }
      // });
    },
    phoneFormat(phone) {
      return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },


    // front ui
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },

  mounted() {
    this.GetReceipt();
    // this.PurchasedItem();
  },
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}

.vc-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  background-color: transparent;
}
</style>
