import { render, staticRenderFns } from "./BookMarkList.vue?vue&type=template&id=7b6bc7e0&scoped=true"
import script from "./BookMarkList.vue?vue&type=script&lang=js"
export * from "./BookMarkList.vue?vue&type=script&lang=js"
import style0 from "./BookMarkList.vue?vue&type=style&index=0&id=7b6bc7e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6bc7e0",
  null
  
)

export default component.exports