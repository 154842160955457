<template>
    <div id="history" class="bg-gray-relative-100">
        <div class="pb-3">
            <SubHeader title="거래내역"></SubHeader>
        </div>
        <ul class="category flex-between-center fs-px-13 gap-2 fw-bold rounded-pill bg-gray-relative-200 mx-3 mb-2">
            <li class="w-33 text-center rounded-pill py-2" @click="ChangeCategory('')" :class="{ 'bg-main text-white': type === '' }">전체</li>
            <li class="w-33 text-center rounded-pill p-2" @click="ChangeCategory('buy')" :class="{ 'bg-main text-white': type === 'buy' }">구매(충전)</li>
            <li class="w-33 text-center rounded-pill p-2" @click="ChangeCategory('check')" :class="{ 'bg-main text-white': type === 'check' }">사용</li>
            <li class="w-33 text-center rounded-pill p-2" @click="ChangeCategory('gift')" :class="{ 'bg-main text-white': type === 'gift' }">선물</li>
        </ul>

        <div class="mx-3 bg-body rounded-4 shadow-400">
            <div class="p-2 pt-3 pb-0">
                <div class="text-end px-2 ps-3">
                    <span class="p-1 px-3 border rounded-2 border-gray-relative-300 fs-px-12 text-muted" @click="rangeSetting = !rangeSetting">
                        <i class="far fa-calendar-alt opacity-50 text-muted me-2"></i>
                        기간설정 - {{ day === '0' ? '당일' : day === '7' ? '7일' : day === '15' ? '15일' : '30일'}}
                        <i class="far fa-xs ms-1" :class="{ 'fa-chevron-down': !rangeSetting, 'fa-chevron-up': rangeSetting,}"></i>
                    </span>
                </div>

                <div class="pb-3 pt-2 border-bottom" v-show="rangeSetting">
                    <ul class="d-flex gap-2 px-2 mb-2">
                        <li class="w-25">
                            <span class="w-100 badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{ 'bg-gray-relative-200': day === '0' }" @click="ClickDay('0')">당일</span>
                        </li>
                        <li class="w-25">
                            <span class="w-100 badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{ 'bg-gray-relative-200': day === '7' }" @click="ClickDay('7')">7일</span>
                        </li>
                        <li class="w-25">
                            <span class="w-100 badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{ 'bg-gray-relative-200': day === '15' }" @click="ClickDay('15')">15일</span>
                        </li>
                        <li class="w-25">
                            <span class="w-100 badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{ 'bg-gray-relative-200': day === '30' }" @click="ClickDay('30')">30일</span>
                        </li>
                    </ul>
                    <div class="d-flex px-2 gap-2">
                        <select class="form-select form-select-sm bg-body border-gray-relative-300 text-body" v-model="year" @change="ChangeYear()">
                            <option class="text-body" value="">전체</option>
                            <option class="text-body" v-for="(item, index) in year_list" :key="index" :value="item.value">{{ item.name }}</option>
                        </select>
                        <select class="form-select form-select-sm bg-body border-gray-relative-300 text-body" v-model="month" @change="ChangeMonth()">
                            <option class="text-body" value="">전체</option>
                            <option class="text-body" v-for="(item, index) in month_list" :key="index" :value="item.value">{{ item.name }}</option>
                        </select>
                        <button class="btn btn-sm bg-secondary text-white w-100" @click="GetMemberHistory()">조회</button>
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <ul class="mx-3 last-border-0" v-if="list && list.length > 0">
                    <li class="px-2 py-3 flex-between-center border-bottom border-gray-relative-200 mb-2" v-for="(item, index) in list" :key="index" @click="DetailHistory(item)" :class="{ pointer: item.status === 'H' }">
                        <div class="flex-between-center">
                            <!-- {{item}} -->
                            <span class="badge w-px-42 h-px-42 rounded-3 fw-normal fs-px-12 px-2 me-3 d-flex flex-column align-items-center justify-content-center shadow" :class="CheckStatusItem(item)">
                                <i class="fal fs-px-15" :class="CheckStatusItemIcon(item)"></i>
                            </span>
                            <div class="d-flex flex-column fs-px-13">
                                <p class="lh-1 fw-bold">
                                    {{ CheckName(item) }}
                                </p>
                                <small class="text-gray-relative-500">{{ item.date }}</small>
                            </div>
                        </div>

                        <div class="d-flex flex-column">
                            <span class="fs-px-12 text-end text-gray-relative-500">
                                <span class="fw-bold text-success" v-if="item.status === 'Y'">{{CheckStatusType(item)}}</span>
                                <span class="fw-bold" v-else-if="item.status === 'H'">구매 대기</span>
                                <span class="fw-bold" v-else-if="item.status === 'C'">취소</span>
                                <span class="fw-bold text-primary" v-else-if="item.type === 'gift'">선물 받음</span>
                                <span class="fw-bold text-send" v-else>선물 보냄</span>
                            </span>
                            <small class="text-end" v-if=" item.type === 'buy' || item.type === 'check' || item.type === 'add'">
                                <i class="fal fa-won-sign fa-xs me-1"></i><b>{{ CheckPrice(item.price) }}</b>
                            </small>
                            <small class="text-end fw-bold fs-px-12" v-else :class="{ 'text-secondary': item.status === 'wait' }">
                                {{ item.memo }}
                            </small>
                        </div>
                    </li>
                </ul>
                <div class="p-3 py-6 text-center text-gray-relative-600" v-else>
                    <i class="fal fa-shopping-cart fa-3x d-block my-3"></i>
                    <p class="my-2 fs-px-13">
                        거래 내역이 없습니다.<br />상점에서 상품권을 구입하고 선물할 수 있습니다.
                    </p>
                </div>
            </div>
        </div>
        <PurchasePopup ref="PurchasePopup" :item_info="item_info"></PurchasePopup>
        <CardPopup ref="CardPopup" :item_info="item_info"></CardPopup>
        <GiftPopup ref="GiftPopup" :item_info="info"></GiftPopup>
    </div>
</template>

<script>
import PurchasePopup from '@/components/popup/Detail_Purchase.vue';
import CardPopup from '@/components/popup/Detail_Card.vue';
import GiftPopup from '@/components/popup/Detail_Gift.vue';
const CryptoJS = require('crypto-js');
import SubHeader from '@/components/common/SubHeader.vue';
import GiftCard from '@/components/mypage/GiftCardComponent.vue';
export default {
    components: {
        SubHeader,
        PurchasePopup,
        CardPopup,
        GiftPopup,
        GiftCard,
    },
    data() {
        return {
            item_info: {},
            info: {},
            rangeSetting: false,
            type: '',
            day: '30',
            year: '',
            month: '',
            total_point: 0,
            year_list: [
                {
                    value: '2023',
                    name: '2023년',
                },
                {
                    value: '2022',
                    name: '2022년',
                },
                {
                    value: '2021',
                    name: '2021년',
                },
                {
                    value: '2020',
                    name: '2020년',
                },
            ],
            month_list: [
                { value: '01', name: '1월',},
                { value: '02', name: '2월',},
                { value: '03', name: '3월',},
                { value: '04', name: '4월',},
                { value: '05', name: '5월',},
                { value: '06', name: '6월',},
                { value: '07', name: '7월',},
                { value: '08', name: '8월',},
                { value: '09', name: '9월',},
                { value: '10', name: '10월',},
                { value: '11', name: '11월',},
                { value: '12', name: '12월',},
            ],
            my_item: [],
            history: [],
            list: [],
        };
    },
    created() {
        this.GetMemberTotalCouponPrice();
        this.GetMemberHistory();
        this.year_list = this.generateYearList(new Date().getFullYear(), 5);
    },
    methods: {
        generateYearList(currentYear, numberOfYears) {
            let yearList = [];
            for (let i = 0; i < numberOfYears; i++) {
                const year = currentYear - i;
                yearList.push({ value: year.toString(), name: `${year}년`});
            }
            return yearList;
        },
        CheckName(item) {
            if (item.coupon_code == '') {
                if (item.type == 'check') {
                    if(item.status =='C'){
                        return '캐쉬 적립';
                    }else{
                        return '캐쉬 사용';
                    }
                    
                } else if (item.type == 'buy') {
                    return '캐쉬 충전';
                } else if (item.type == 'add') {
                    return '잔여금액충전';
                }
            } else {
                if(item.type =='buy'){
                    if(item.payment_type =='B'){
                        return "무통장 입금"
                    }else if(item.payment_type =='C'){
                        return "신용(체크) 카드"
                    }
                }else{
                    return item.coupon_name;
                }
                
            }
        },
        CheckStatusType(item) {
            const type = item.type;
            const code = item.coupon_code;
            if (code == '') {
                if (type == 'buy') {
                    return '캐쉬충전';
                } else if (type == 'check') {
                    return '캐쉬사용';
                } else if (type == 'add') {
                    return '잔여금액충전';
                }
            } else {
                if (type == 'buy') {
                    return '구매승인';
                } else if (type == 'check') {
                    return '사용승인';
                } else if (type == 'add') {
                    return '등록승인';
                } else if (type == 'gift') {
                    if (item.sender_code == '') {
                        return '선물보냄';
                    } else {
                        return '선물받음';
                    }
                }
            }
        },
        CheckStatusItem(item) {
            if (item.status == 'H') {
                return 'bg-gray-relative-500';
            } else if (item.status == 'Y') {
                if (item.type == 'buy') {
                    return 'buy_type';
                } else if (item.type == 'add') {
                    return 'buy_type';
                } else if (item.type == 'check') {
                    return 'check_type';
                } else if (item.type == 'gift') {
                    return 'send_type';
                }
            } else if (item.status == 'C') {
                return 'cancel_type';
            }
        },
        CheckStatusItemIcon(item) {
            if (item.status == 'H') {
                return 'fa-ellipsis-h';
            } else if (item.status == 'Y') {
                if (item.type == 'buy') {
                    return 'fa-shopping-cart';
                } else if (item.type == 'add') {
                    return 'fa-shopping-cart';
                } else if (item.type == 'check') {
                    return 'fa-check';
                } else if (item.type == 'gift') {
                    return 'fa-gift-card';
                }
            } else if (item.status == 'C') {
                return 'fa-minus';
            }
        },
        ChangeYear() {
            this.day = '';
            this.GetMemberHistory();
        },
        ChangeMonth() {
            this.day = '';
            this.GetMemberHistory();
        },
        ClickDay(day) {
            this.day = day;
            this.year = '';
            this.month = '';
            this.GetMemberHistory();
        },
        CheckPrice(price) {
            const amount = price * 1;
            return amount.toLocaleString();
        },
        GetMemberTotalCouponPrice() {
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/mypage/GetMemberTotalCouponPrice', { req }).then((res) => {
                if (res.status == 200) {
                    this.loading = false;
                    if (res.data.code == '200') {
                        
                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);
                        
                        this.total_point = body.price;
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },

        GetMemberHistory() {
            
            const day = this.day;
            const year = this.year;
            const month = this.month;
            const type = this.type;

            const body = { day, year, month, type };
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/front/mypage/GetMemberHistory', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {

                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);
                        this.list = body.list;
                        
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        ChangeCategory(type) {
            this.type = type;
            this.GetMemberHistory();
        },
        DetailHistory(item) {
            if (item.status == 'H') {
                // console.log(item)
                if(item.payment_type =='B'){
                    this.$refs.PurchasePopup.pp = true;
                }else {
                    this.$refs.CardPopup.pp = true;
                }
                this.item_info = item;
            } else if (item.status == 'Y') {
                if (item.type == 'gift') {

                    const sender_memo = item.send_memo;
                    const sender = item.memo;
                    const sender_code = item.sender_code;
                    const send_date = item.date;
                    const coupon_name = item.coupon_name;
                    const coupon_image = item.coupon_image;

                    this.info = { sender, sender_memo, sender_code, coupon_name, coupon_image, send_date};

                    this.$refs.GiftPopup.pp = true;
                }
            }
        },
    },
    mounted() {
        this.filter_list = this.history;
    },
};
</script>

<style lang="scss" scoped>
#history {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
  word-break: keep-all;
}
.buy_type {
  background: linear-gradient(to bottom, #5d9cec, #4a89dc);
}
.send_type {
  background: linear-gradient(to bottom, #3f51b5, #243e87);
}
.cancel_type {
  background: linear-gradient(to bottom, #ee2e0d, #df741d);
}
.text-send {
  color: #3f51b5;
}
.check_type {
  background: linear-gradient(to bottom, #a0d468, #8cc152);
}

.pointer {
  cursor: pointer;
}
</style>
