<template>
    <div class="basic_pp bottom_up" v-show="pp"  :class="{'off':off}">
        <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
        <div class="content bg-body text-center" ref="basic_pp_body">
            <div class="text-end mb-2">
                <button class="btn border-0" @click="pp_off()"><i class="fal fa-times ms-auto text-gray-relative-500"></i></button>
            </div>
            <div class="filter-invert-only-dark">
                <barcode :value="item_info.coupon_code"  ref="barcode" width="1px"></barcode>
            </div>
            <p class="text-truncate py-2 w-80 mx-auto">{{item_info.name}}</p>
            <div class="cutting_line border-gray-relative-300 w-100 h-0 my-4"></div>
            <div class="px-3 text-start mb-3">
                <b class="fs-px-14">모바일 QR코드</b>
                <p class="text-gray-relative-500 fs-px-12">상품권 사용 시 가맹점에서 해당 QR코드를 제시하여 주시기 바랍니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
import VueBarcode from 'vue-barcode';

    export default {
        data(){
            return{
               pp: false,
               off: false,
               timeout_id: null,
            }
        },
        components: {
            'barcode': VueBarcode
        },
       props:{
           item_info:{
               type:Object,
           }
       },
       mounted(){
       },
       methods:{
    
           // front ui
           pp_off(){
               this.off = true;
    
               if (this.timeout_id) {
                   clearTimeout(this.timeout_id);
               }
    
               this.timeout_id = setTimeout(() => {
                   this.pp = false;
                   this.off = false;
                    if( this.$refs.basic_pp_body ){
                        this.$refs.basic_pp_body.scrollTop = 0;}
                   this.timeout_id = null;
               }, 200);
           }
       }
    
    }
</script>
<style lang="scss" scoped>
    .cutting_line{
    border-top: 1px dashed;
    }
    .bank_logo{
    width: 150px;
    margin: 0 auto;
    object-fit: cover;
    margin-bottom: 10px;
    }
    .basic_pp .content{
    max-height: 90vh;
    }

    .vue-barcode-element{
        width:100% !important
    }
</style>