<template>
    <div class="bg-gray-relative-100">
        <SubHeader title="내 찜 목록" :bookmark="false"></SubHeader>

		<div>
			<BookMarkList :list="list" />
		</div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
// import SearchList from '@/components/search/SearchList.vue'
import BookMarkList from '@/components/search/BookMarkList.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader, BookMarkList
    },
    data(){
        return{
			list: [
			],
			
		}
    },
	mounted(){
		this.GetBookmarkList();

	},
	methods : {
		GetBookmarkList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/front/mypage/GetBookmarkList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
						if(res.data.code =='200'){
							const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)

							this.list = body.list;
						}else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );              
            
        },
	}
}
</script>
<style lang="scss" scoped>
    .search_list {
        min-height: calc(100vh - 141px);
    }
</style>